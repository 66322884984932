import React from "react";
import avatar from "../avatar_3.png";
import { useState, useEffect, useRef } from "react";
import { personalSkills, aboutDetailsInfo } from "../data.js";

function About({ isAboutClicked, setIsAboutClicked, fadeFunctions }) {
  const { fadeUpFunction, fadeLeftFunction, fadeRightFunction, options } =
    fadeFunctions;
  const [details, setDetails] = useState(aboutDetailsInfo);
  const [value, setValue] = useState(0);
  const [isReadMore, setIsReadMore] = useState(false);
  const aboutSectionRef = useRef(null);

  const setTabs = (index) => {
    setValue(index);
  };
  const { category, info } = details[value];

  useEffect(() => {
    if (isAboutClicked) {
      aboutSectionRef.current.scrollIntoView({ behavior: "smooth" });
      setIsAboutClicked(false);
    }
  }, [isAboutClicked]);

  useEffect(() => {
    if (isReadMore) {
      console.log("Okay");
    }
  }, [isReadMore]);

  useEffect(() => {
    const fu_observer = new IntersectionObserver(fadeUpFunction, options);
    const fl_observer = new IntersectionObserver(fadeLeftFunction, options);
    const fr_observer = new IntersectionObserver(fadeRightFunction, options);
    const fadeUpEle = document.querySelectorAll(
      ".opacity-0[data-name-animation='fade-up']"
    );
    const fadeLeftEle = document.querySelectorAll(
      ".opacity-0[data-name-animation='fade-left']"
    );
    const fadeRightEle = document.querySelectorAll(
      "[data-name-animation='fade-right']"
    );
    fadeUpEle.forEach((ele) => {
      fu_observer.observe(ele);
    });
    fadeLeftEle.forEach((ele) => {
      fl_observer.observe(ele);
    });
    fadeRightEle.forEach((ele) => {
      fr_observer.observe(ele);
    });
    // fl_observer.observe(fadeLeftRef.current);
  }, []);

  return (
    <section
      className="section container about-container block py-10 xl:py-12 m-auto xl:flex xl:flex-wrap xl:flex-col px-5 lg:px-10 xl:px-10 2xl:px-0"
      ref={aboutSectionRef}
    >
      <h2
        className="section-title font-bold text-5xl md:text-8xl underline underline-offset-8 md:no-underline opacity-0"
        data-name-animation="fade-up"
      >
        about
      </h2>
      <div className="flex flex-col xl:flex-row relative m-auto flex-wrap pt-3 md:pt-8">
        <div className="about-avt flex w-full xl:w-1/4 relative h-auto justify-center">
          <div
            className="avt-bg-circle items-center justify-center opacity-0"
            data-name-animation="fade-left"
          >
            <img src={avatar} alt="avatar" />
          </div>
        </div>
        <div className="about-info w-creen xl:w-3/4 xl:pl-28 flex justify-center flex-col py-10 xl:py-0">
          <p
            className="text-2xl lg:text-3xl font-normal leading-normal xl:leading-relaxed opacity-0"
            data-name-animation="fade-right"
          >
            hello &#128587; this is Hoang Tan Phat - a front-end developer that
            you're looking for &#128037;
          </p>
          <button
            className={`${
              isReadMore
                ? "hidden"
                : "md:hidden text-2xl border-2 rounded-[20px] w-fit self-center px-8 py-2 mb-2 opacity-0"
            }`}
            data-name-animation="fade-right"
            onClick={() => setIsReadMore(true)}
          >
            &#10024; read more &#10024;
          </button>
          <p
            className={`${
              isReadMore
                ? "block text-2xl lg:text-3xl font-normal leading-normal xl:leading-relaxed	opacity-0"
                : "hidden md:block text-2xl lg:text-3xl font-normal leading-normal xl:leading-relaxed opacity-0"
            }`}
            data-name-animation="fade-right"
          >
            after graduating in 2019, i decided to turn my passion into a career
            &#128129; i chose to be a website developer instead of following my
            old path - which was becoming an electronic engineer, and i'm always
            proud that i'm still living in my own dreams &#127752;
          </p>
          {/* <p className="text-2xl lg:text-3xl font-normal leading-normal opacity-0" data-name-animation="fade-right">
                        in my opinion, programming is a balance of art and science, which requires a lot of imagination to write beautiful and elegant code &#129302; that's the reason why i prefer working in a flexible, positive and comfortable working environment, where i can easily let my imagination to do its jobs &#128077; 
                    </p> */}
          <p
            className={`${
              isReadMore
                ? "block text-2xl lg:text-3xl font-normal leading-normal xl:leading-relaxed	opacity-0"
                : "hidden md:block text-2xl lg:text-3xl font-normal leading-normal xl:leading-relaxed opacity-0"
            }`}
            data-name-animation="fade-right"
          >
            i'm always looking for an opportunity to learn and improve my skills
            &#127947; and how wonderful it is to start a new day with a cup of
            coffee and end it with a party with all of my fellows &#128150;
            &#128171; &#128131;
          </p>
          {personalSkills.map((item) => {
            const { title, id, value } = item;
            const customWidth = `${value}%`;
            return (
              <div
                key={id}
                className="peronal-skills-rate-container flex py-3 flex-col xl:flex-row opacity-0"
                data-name-animation="fade-right"
              >
                <span className="text-2xl lg:text-3xl xl:w-6/12 w-full">
                  {title}
                </span>
                <div className="personal-skills-rate w-full xl:w-6/12">
                  <div
                    className="personal-skills-rate-bar text-2xl lg:text-3xl flex justify-center"
                    style={{ width: customWidth }}
                  >
                    {`${value}%`}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="about-details-container md:grid md:grid-cols-7 w-full block pt-12">
          <h2 className="md:hidden text-5xl text-center font-bold underline underline-offset-8 md:no-underline">
            details
          </h2>
          <div className="md:col-span-2 text-2xl lg:text-3xl font-normal text-center">
            <div
              className="about-details-categories flex flex-row md:flex-col flex-wrap opacity-0"
              data-name-animation="fade-left"
            >
              {details.map((item, index) => {
                return (
                  <button
                    key={item.category}
                    className={`info-category-btn py-2 my-2 hover:text-black transition ${
                      index === value && "active-btn"
                    }`}
                    onClick={() => setTabs(index)}
                  >
                    {item.category}
                  </button>
                );
              })}
            </div>
          </div>
          <div className="md:col-span-5 font-normal md:pl-12 transition">
            <div
              className="about-details-info py-7 px-7 flex flex-col justify-center opacity-0"
              data-name-animation="fade-right"
            >
              {info.map((item, index) => {
                const { title, time, position, desp } = item;
                return (
                  <div
                    key={index}
                    className={`details-text cursor-default pt-10 md:pt-5`}
                    data-name-animation="fade-right"
                  >
                    <div className="flex justify-between flex-col md:flex-row details-text-title tra">
                      <h3 className="text-2xl transition mb-2 md:mb-5">
                        {title}
                      </h3>
                      {time && (
                        <span className="text-2xl transition">{time}</span>
                      )}
                      {/* <span className="text-2xl">{time}</span> */}
                    </div>
                    {position && desp && (
                      <div className="details-text-info pt-5 md:pt-0">
                        <p className="text-2xl italic transition">{position}</p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
