import {React, useState, useEffect} from 'react'
import ReactLoading from 'react-loading';

function Loading({loading}) {
    return (
        <div className='relative w-screen h-screen bg-black z-[999] overflow-y-hidden'>
            <div className='absolute top-2/4 left-2/4 translate-x-[-50%] translate-y-[-50%] flex flex-col'>
            <div className="lds-facebook self-center"><div></div><div></div><div></div></div>
            <h3 className='text-2xl'>Loading</h3>
            </div>
        </div>
    );
}
export default Loading;
