import nextJsIcon from "./img/nextjs-icon.png";
import vueJsIcon from "./img/vuejs-icon.png";
import muiIcon from "./img/mui-icon.png";

export const links = [
  {
    id: 1,
    // url: '/#about',
    text: "about",
  },
  {
    id: 2,
    // url: '/#skills',
    text: "skills",
  },
  {
    id: 3,
    // url: '/#projects',
    text: "projects",
  },
  {
    id: 4,
    // url: '/#contact',
    text: "contact",
  },
];

export const personalSkills = [
  {
    id: 1,
    title: "communication",
    value: 70,
  },
  {
    id: 2,
    title: "teamwork",
    value: 70,
  },
  {
    id: 3,
    title: "self-learning",
    value: 80,
  },
  {
    id: 4,
    title: "problem solving",
    value: 70,
  },
];

export const aboutDetailsInfo = [
  {
    category: "experience",
    info: [
      {
        id: 1,
        title: "work at Ahamove",
        time: "04/2022 - 5/2023",
        position: "full time - front-end developer",
        desp: "Des1",
      },
      {
        id: 2,
        title: "work at TalearnX",
        time: "06/2020 - 11/2021",
        position: "full time - front-end developer",
        desp: "Des2",
      },
      {
        id: 3,
        title: "internship at Intel Vietnam",
        time: "10/2018 - 07/2019",
        position: "full time - equipment and process engineer",
        desp: "Desp3",
      },
      {
        id: 4,
        title: "freelance",
        time: "at gap time",
        position: "",
        desp: "",
      },
    ],
  },
  {
    category: "education",
    info: [
      {
        title: "study at university of technology (BKU)",
        time: "2015 - 2019",
        position: "bachelor of engineering in electronics - telecommunications",
      },
    ],
  },
  {
    category: "activities",
    info: [
      {
        title: "member of UTEC - university of technology english club",
        time: "2015 - 2017",
      },
      { title: "organizer of a talent contest", time: "3 - 2016" },
    ],
  },
];

export const skills = [
  {
    name: "react js",
    img: "https://cdn4.iconfinder.com/data/icons/logos-3/600/React.js_logo-512.png",
  },
  {
    name: "wordpress",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/98/WordPress_blue_logo.svg/1024px-WordPress_blue_logo.svg.png",
  },
  {
    name: "nextJs",
    img: nextJsIcon,
  },
  {
    name: "vueJs",
    img: vueJsIcon,
  },
  {
    name: "redux - redux toolkit",
    img: muiIcon,
  },
  {
    name: "material UI",
    img: "",
  },
  {
    name: "bootstrap 5",
    img: "",
  },

  {
    name: "tailwindcss",
    img: "",
  },
  {
    name: "ethers - web3 react",
    img: "",
  },
  {
    name: "gitlab",
    img: "",
  },
];

export const projects = [
  {
    id: 1,
    type: "Saigon Vibes (freelance)",
    link: "https://saigonvibes.com/",
    name: "Saigon Vibes",
    url: "/img/saigon-vibes.png",
    languages: ["wordpress"],
  },
  {
    id: 2,
    type: "nft marketplace (in-house)",
    link: "",
    name: "nft marketplace (in-house)",
    url: "/img/nft-landing-page.png",
    languages: ["nextjs", "material UI", "ethers - web3 react"],
  },
  {
    id: 3,
    type: "gamification (in-house)",
    link: "",
    name: "gamification (in-house)",
    url: "/img/lucky-draw.png",
    languages: ["reactjs", "material UI"],
  },
  {
    id: 4,
    type: "email signature generator",
    link: "https://hoangtanphat.github.io/email-signature-generator/",
    name: "email signature generator",
    url: "/img/email-signature-generator.png",
    languages: ["reactjs", "bootstrap 5", "redux core"],
  },
  {
    id: 5,
    type: "qr code generator",
    link: "https://hoangtanphat.github.io/vietqr-code-generator/",
    name: "qr code generator",
    url: "/img/qr-code-generator.png",
    languages: ["reactjs", "bootstrap 5", "vietqr API"],
  },
  {
    id: 6,
    type: "portfolio website",
    link: "https://hoangtanphatvn.com/",
    name: "hoangtanphatvn.com",
    url: "/img/portfolioweb.jpg",
    languages: ["react js", "tailwind css"],
  },
  {
    id: 7,
    type: "talearnx website",
    link: "https://talearnx.com/",
    name: "talearnx",
    url: "/img/talearnxwebsite.jpg",
    languages: ["wordpress", "custom html/css/js"],
  },
  {
    id: 8,
    type: "school thesis",
    link: "",
    name: "data collection in health care system",
    url: "/img/schoolthesis.jpg",
    languages: ["php + mysql", "semantic ui"],
  },
  {
    id: 9,
    type: "side projects",
    link: "",
    name: "my freelance job and company works",
    url: "/img/freelancer.jpg",
    languages: ["html/css/js", "bootstrap 5"],
  },
];
