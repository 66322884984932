import { React, useEffect, useState, useRef } from "react";
import { projects } from "../data";
import { FaCaretDown } from "react-icons/fa";
import { FiExternalLink } from "react-icons/fi";

function Projects({ isProjectsClicked, setIsProjectsClicked, fadeFunctions }) {
  const { fadeUpFunction, options } = fadeFunctions;
  const [tabIndex, setTabIndex] = useState(1);
  const [typeList, setTypeList] = useState("");
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const projectsSectionRef = useRef(null);
  const { id, type, link, name, url, languages } = projects[tabIndex - 1];

  const projectsDropdown = () => {
    if (!isTypeOpen) {
      document.querySelector(".dropdown-box").style.height = "100%";
      const newList = projects.filter((project) => project.id !== tabIndex);
      setTypeList(newList);
      setIsTypeOpen(true);
    } else {
      document.querySelector(".dropdown-box").style.height = "40px";
      setTypeList("");
      setIsTypeOpen(false);
    }
  };

  const handleImageLoaded = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (isProjectsClicked) {
      projectsSectionRef.current.scrollIntoView({ behavior: "smooth" });
      setIsProjectsClicked(false);
    }
  }, [isProjectsClicked]);

  useEffect(() => {
    const fu_observer = new IntersectionObserver(fadeUpFunction, options);
    const fadeUpEle = document.querySelectorAll(
      ".opacity-0[data-name-animation='fade-up']"
    );
    fadeUpEle.forEach((ele) => {
      fu_observer.observe(ele);
    });
  }, []);

  return (
    <section
      className="section container projects-container block pt-12 xl:py-12 m-auto md:flex md:flex-wrap md:flex-col px-5 lg:px-10 xl:px-10 2xl:px-0"
      ref={projectsSectionRef}
    >
      <h2
        className="section-title font-bold text-5xl md:text-8xl underline underline-offset-8 md:no-underline titles-anim opacity-0"
        data-name-animation="fade-up"
      >
        projects
      </h2>
      <div
        className="flex flex-col xl:flex-row relative flex-wrap pt-5 md:pt-12 opacity-0"
        data-name-animation="fade-up"
      >
        <div className="projects-tabs justify-around w-full m-auto hidden md:grid md:grid-cols-3">
          {projects.map((project, index) => {
            const { type } = project;
            return (
              <div
                key={index}
                className={`m-4 mt-2 cursor-pointer projects-type-items py-2 lg:py-0 transition whitespace-nowrap truncate ${
                  tabIndex === index + 1
                    ? "font-bold text-3xl active-tab"
                    : "text-3xl"
                }`}
                onClick={() => {
                  setTabIndex(index + 1);
                  setIsLoading(true);
                }}
              >
                {type}
              </div>
            );
          })}
        </div>
        <div
          className="md:hidden dropdown-projects-tabs px-[10px] flex flex-row"
          style={{ width: "100%" }}
        >
          <div
            className="dropdown-box w-[100%] h-[40px] overflow-hidden flex"
            style={{ flexFlow: "wrap", flexDirection: "row" }}
            onClick={projectsDropdown}
          >
            <div className="flex flex-column w-[100%] h-[100%]">
              <span
                className="cursor-pointer h-full transition text-2xl w-full flex flex-row items-center py-3"
                style={{
                  textOverflow: "hidden",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                onClick={() => {
                  setTabIndex(id);
                }}
              >
                {type}
              </span>
              <div className="w-[15%] flex items-center">
                <FaCaretDown
                  style={{ width: "1.5rem", height: "1.5rem", flexGrow: "1" }}
                />
              </div>
            </div>

            {isTypeOpen
              ? typeList.map((list) => {
                  const { id, type } = list;
                  return (
                    <span
                      className="cursor-pointer h-full projects-type-items transition text-2xl w-full flex flex-row items-center py-3"
                      onClick={() => setTabIndex(id)}
                      style={{
                        textOverflow: "hidden",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {type}
                    </span>
                  );
                })
              : ""}
          </div>
          {/* <div className='w-[15%] flex justify-center items-center'> 
                        <FaCaretDown style={{width:"1.5rem",height:"1.5rem"}} />
                    </div> */}
        </div>
        <div className="projects-content flex-wrap w-full py-10">
          <div className="rounded-[20px] lg:h-[350px] border border-[#d4d4d4] flex flex-col lg:flex-row flex-wrap py-5 px-5">
            <div className="w-full lg:w-1/2 flex flex-wrap items-center h-full overflow-hidden justify-center">
              <div className="projects-content-img-wrapper h-full">
                {isLoading ? (
                  <div className="block h-full w-full animate-pulse bg-neutral-700 rounded"></div>
                ) : null}
                <img
                  src={url}
                  alt={name}
                  className="block h-full w-full object-cover rounded"
                  onLoad={handleImageLoaded}
                />
              </div>
            </div>
            <div className="w-full lg:w-1/2 flex flex-wrap flex-col pt-6 lg:pt-0 md:px-12 lg:px-16 justify-center">
              {link ? (
                <a
                  href={link}
                  target="__blank"
                  className="font-bold text-2xl lg:text-3xl text-center w-full break-words underline"
                >
                  {name}
                  <span className="inline-block">
                    <FiExternalLink className="ml-2" />
                  </span>
                </a>
              ) : (
                <p className="font-bold text-2xl lg:text-3xl text-center w-full break-words">
                  {name}
                </p>
              )}

              <div className="text-left w-full mt-5">
                <p className="text-2xl">language/framework used:</p>
                {/* {languages.map((id, language) => {
                                    return(<p key={id} className="text-2xl">{language}</p>);
                                })} */}
                {languages.map((item, index) => {
                  return (
                    <p key={index} className="languages text-2xl pl-5">
                      {item}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Projects;
