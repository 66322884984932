import "./index.css";
import Loading from "./components/Loading";

function Loader({loading}) {
    if(loading) {
        return (
            <Loading />
        )
    }
    return (
        <></>
    );
}
export default Loader;