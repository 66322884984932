import React from "react";
import "../index.css";
import { useState, useEffect, useRef } from "react";
import Navbar from "../components/Navbar";
import About from "./About";
import avatar from "../avatar.png";
function Hero({ scrollToSection, handleLoading }) {
  const heroSectionRef = useRef(null);

  const loaded = () => {
    requestAnimationFrame(startRender);
  };
  const startRender = () => {
    requestAnimationFrame(rendered);
  };
  const rendered = () => {
    handleLoading();
  };

  return (
    <section
      className="section hero-container xl:px-32 relative m-auto overflow-hidden"
      ref={heroSectionRef}
    >
      <div className="hero h-screen md:h-auto">
        <div className="marquee marquee-top marquee-lr">
          <div className="marquee__inner">
            <span className="text-8xl font-bold">HOANG TAN PHAT</span>
            <span className="text-8xl font-bold">HOANG TAN PHAT</span>
            <span className="text-8xl font-bold">HOANG TAN PHAT</span>
            <span className="text-8xl font-bold">HOANG TAN PHAT</span>
            {/* <span className="text-8xl font-bold animation-text text-lr inline-block">
              HOANG TAN PHAT
            </span>

            <span className="text-8xl font-bold animation-text text-lr inline-block">
              HOANG TAN PHAT
            </span> */}
          </div>
        </div>
        <h1 className="text-8xl lg:text-9xl font-bold absolute hidden md:block hero-title z-99 fadeleft-hero">
          hello;
        </h1>
        <div className="marquee marquee-bottom marquee-rl">
          <div className="marquee__inner">
            <span className="text-8xl font-bold">HOANG TAN PHAT</span>
            <span className="text-8xl font-bold">HOANG TAN PHAT</span>
            <span className="text-8xl font-bold">HOANG TAN PHAT</span>
            <span className="text-8xl font-bold">HOANG TAN PHAT</span>
          </div>
        </div>
        {/* <div className="text-path-animation absolute bottom-0 left-0">
          <span className="text-8xl font-bold animation-text z-1 mb-0 text-rl">
            HOANG TAN PHAT HOANG TAN PHAT HOANG TAN PHAT HOANG TAN PHAT HOANG
            TAN PHAT HOANG TAN PHAT HOANG TAN PHAT HOANG TAN PHAT HOANG TAN PHAT
            HOANG TAN PHAT HOANG TAN PHAT HOANG TAN PHAT HOANG TAN PHAT HOANG
            TAN PHAT HOANG TAN PHAT HOANG TAN PHAT HOANG TAN PHAT HOANG TAN PHAT
          </span>
        </div> */}
        <div className="navbar-container hidden md:block faderight">
          <Navbar scrollToSection={scrollToSection} />
        </div>
        <div className="avatar-container hidden md:block fadeup">
          <div className="avatar-wrap">
            <div className="avatar">
              <img
                src={avatar}
                alt="Avatar"
                className="max-w-full"
                onLoad={loaded}
              />
            </div>
          </div>
        </div>
        <div className="mobile-navbar-container flex flex-col flex-wrap justify-center md:hidden h-full">
          <h1 className="text-8xl font-bold mb-0 self-center py-5">hello;</h1>
          <Navbar scrollToSection={scrollToSection} />
        </div>
      </div>
    </section>
  );
}

export default Hero;
