import React from 'react'
import { useState } from 'react';
import {links} from '../data';
import { FaBars } from 'react-icons/fa'
function Navbar({scrollToSection}) {
    const [showLinks, setShowLinks] = useState(false);
    // const scrollToSection = (section) => {
    //     console.log(section);
    // }
    return (
        <div className="navbar inline-flex justify-center">
            <ul className="nav-list">
                {links.map((link) => {
                    const {id, text} = link;
                    return(
                        <li key={id} className="font-bold text-4xl nav-item leading-normal md:leading-normal transition cursor-pointer md:text-5xl">
                            <a onClick={()=>scrollToSection(text)}>{text}</a>
                        </li>
                    );
                })}
            </ul>
        </div>
    )
}

export default Navbar
