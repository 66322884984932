import { React, useEffect, useRef } from "react";
import { skills } from "../data";
function Skills({ isSkillsClicked, setIsSkillsClicked, fadeFunctions }) {
  const { fadeUpFunction, fadeLeftFunction, fadeRightFunction, options } =
    fadeFunctions;
  const skillsSectionRef = useRef(null);
  useEffect(() => {
    if (isSkillsClicked) {
      skillsSectionRef.current.scrollIntoView({ behavior: "smooth" });
      setIsSkillsClicked(false);
    }
  }, [isSkillsClicked]);

  useEffect(() => {
    const fu_observer = new IntersectionObserver(fadeUpFunction, options);
    const fl_observer = new IntersectionObserver(fadeLeftFunction, options);
    const fr_observer = new IntersectionObserver(fadeRightFunction, options);
    const fadeUpEle = document.querySelectorAll(
      ".opacity-0[data-name-animation='fade-up']"
    );
    const fadeLeftEle = document.querySelectorAll(
      ".opacity-0[data-name-animation='fade-left']"
    );
    const fadeRightEle = document.querySelectorAll(
      ".opacity-0[data-name-animation='fade-right']"
    );
    fadeUpEle.forEach((ele) => {
      fu_observer.observe(ele);
    });
    fadeLeftEle.forEach((ele) => {
      fl_observer.observe(ele);
    });
    fadeRightEle.forEach((ele) => {
      fr_observer.observe(ele);
    });
  }, []);

  return (
    <section
      className="section conatiner skills-container pt-12 m-auto flex flex-col flex-wrap justify-between px-5 lg:px-10 xl:px-10 2xl:px-0"
      ref={skillsSectionRef}
    >
      <h2
        className="section-title font-bold text-5xl md:text-8xl underline underline-offset-8 md:no-underline titles-anim opacity-0"
        data-name-animation="fade-up"
      >
        skills
      </h2>
      <div className="flex flex-col md:flex-row relative flex-wrap">
        <div className="skills-img w-full md:w-1/2">
          <div className="skills-img-wrapper relative md:w-[70%] h-[400px] md:h-full m-auto">
            <div className="w-[150px] h-[150px] lg:w-[180px] lg:h-[180px] bubble rj-bubble">
              <img src={skills[1].img} alt={skills[0].name} className="block" />
            </div>
            <div className="w-[200px] h-[200px] lg:w-[230px] lg:h-[230px] bubble wp-bubble">
              <img src={skills[0].img} alt={skills[1].name} className="block" />
            </div>
            <div className="w-[120px] h-[120px] lg:w-[150px] lg:h-[150px] bubble nextjs-bubble bg-white">
              <img src={skills[2].img} alt={skills[2].name} className="block" />
            </div>
            <div className="w-[80px] h-[80px] lg:w-[110px] lg:h-[110px] bubble c-bubble">
              <img src={skills[3].img} alt={skills[3].name} className="block" />
            </div>
            <div className="w-[80px] h-[80px] lg:w-[110px] lg:h-[110px] bubble php-bubble">
              <img src={skills[4].img} alt={skills[4].name} className="block" />
            </div>
          </div>
        </div>
        <div className="skills-text w-full md:w-1/4 flex">
          <div className="w-max flex flex-wrap jutify-center flex-col m-auto">
            {skills
              .filter((_, index) => index < 5)
              .map((skill, index) => {
                const { name } = skill;
                return (
                  <p
                    key={index}
                    className={`text-2xl lg:text-3xl opacity-0 ${
                      index < 1 ? "" : "pt-5"
                    }`}
                    data-name-animation="fade-right"
                  >
                    {name}
                  </p>
                );
              })}
          </div>
        </div>
        <div className="skills-text w-full md:w-1/4 flex">
          <div className="w-max flex flex-wrap jutify-center flex-col m-auto">
            {skills
              .filter((_, index) => index >= 5)
              .map((skill, index) => {
                const { name } = skill;
                return (
                  <p
                    key={index}
                    className={`text-2xl lg:text-3xl opacity-0 ${
                      index < 1 ? "" : "pt-5"
                    }`}
                    data-name-animation="fade-right"
                  >
                    {name}
                  </p>
                );
              })}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;
