import avatar from "./avatar.png";
import "./index.css";
import About from "./pages/About";
import Skills from "./pages/Skills";
import Projects from "./pages/Projects";
import Hero from "./pages/Hero";
import Contact from "./pages/Contact";
import Loader from "./Loader";
// import Loading from "./components/Loading";
import { useState, useEffect, useRef } from "react";

function App() {
  const [loading, setLoading] = useState(true);
  const [isAboutClicked, setIsAboutClicked] = useState(false);
  const [isSkillsClicked, setIsSkillsClicked] = useState(false);
  const [isProjectsClicked, setIsProjectsClicked] = useState(false);
  const [isContactClicked, setIsContactClicked] = useState(false);

  const scrollToSection = (section) => {
    switch (section) {
      case "about":
        setIsAboutClicked(true);
        break;
      case "skills":
        setIsSkillsClicked(true);
        break;
      case "projects":
        setIsProjectsClicked(true);
        break;
      case "contact":
        setIsContactClicked(true);
        break;
      default:
    }
  };

  // Setup Animation Functions on Scroll for Each Elements
  const fadeFunctions = {
    fadeUpFunction: (entries, fu_observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fadeup");
          fu_observer.unobserve(entry.target);
          // console.log("Yes");
        }
      });
    },
    fadeLeftFunction: (entries, fl_observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fadeleft");
          fl_observer.unobserve(entry.target);
        }
      });
    },
    fadeRightFunction: (entries, fr_observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("faderight");
          fr_observer.unobserve(entry.target);
        }
      });
    },
    options: {
      root: null,
      rootMargin: "0px",
      threshold: 0.35,
    },
  };
  const handleLoading = () => {
    setLoading(false);
  };
  return (
    <main
      className={`${
        loading
          ? "h-max fixed overflow-y-hidden"
          : "main-container overflow-hidden"
      }`}
    >
      <Loader loading={loading} />
      <Hero scrollToSection={scrollToSection} handleLoading={handleLoading} />
      <About
        isAboutClicked={isAboutClicked}
        setIsAboutClicked={setIsAboutClicked}
        fadeFunctions={fadeFunctions}
      />
      <Skills
        isSkillsClicked={isSkillsClicked}
        setIsSkillsClicked={setIsSkillsClicked}
        fadeFunctions={fadeFunctions}
      />
      <Projects
        isProjectsClicked={isProjectsClicked}
        setIsProjectsClicked={setIsProjectsClicked}
        fadeFunctions={fadeFunctions}
      />
      <Contact
        isContactClicked={isContactClicked}
        setIsContactClicked={setIsContactClicked}
        fadeFunctions={fadeFunctions}
      />
    </main>
  );
}

export default App;
