import { React, useRef, useEffect } from "react";
import githubLogo from "./../img/github.png";
import linkdeinLogo from "./../img/linkedin.png";

function Contact({ isContactClicked, setIsContactClicked, fadeFunctions }) {
  const { fadeUpFunction } = fadeFunctions;
  const contactSectionRef = useRef(null);
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  };
  useEffect(() => {
    if (isContactClicked) {
      contactSectionRef.current.scrollIntoView({ behavior: "smooth" });
      setIsContactClicked(false);
    }
  }, [isContactClicked]);
  useEffect(() => {
    const fu_observer = new IntersectionObserver(fadeUpFunction, options);
    const fadeUpEle = document.querySelectorAll(
      ".opacity-0[data-name-animation='fade-up']"
    );
    fadeUpEle.forEach((ele) => {
      fu_observer.observe(ele);
    });
  }, []);
  return (
    <section
      className="section container contact-container px-5 lg:px-10 xl:px-10 2xl:px-0 py-12 block xl:py-12 m-auto flex flex-wrap flex-col place-items-center"
      ref={contactSectionRef}
    >
      <h2
        className="section-title font-bold text-5xl md:text-8xl underline underline-offset-8 md:no-underline block w-full titles-anim opacity-0"
        data-name-animation="fade-up"
      >
        contact
      </h2>
      <p
        className="text-center text-2xl lg:text-3xl block w-100 opacity-0"
        data-name-animation="fade-up"
      >
        Please hit me up for more details. Seeyah &#128075;
      </p>
      <div className="pt-5 block w-full">
        <div
          className="text-center flex flex-row justify-center items-center text-2xl lg:text-3xl font-bold opacity-0 mb-4"
          data-name-animation="fade-up"
        >
          <span style={{ width: "40px", height: "40px" }}>
            <a
              style={{ display: "inline-block", width: "40px", height: "40px" }}
              href="https://www.linkedin.com/in/hoang-tan-phat/"
            >
              <img src={linkdeinLogo} alt="Linkedin logo" />
            </a>
          </span>
          <span className="mx-4">|</span>
          <span style={{ width: "40px", height: "40px" }}>
            <a
              style={{ display: "inline-block", width: "40px", height: "40px" }}
              href="https://github.com/HoangTanPhat"
            >
              <img src={githubLogo} alt="Github logo" />
            </a>
          </span>
        </div>
        <p
          className="text-center text-2xl lg:text-3xl break-all opacity-0"
          data-name-animation="fade-up"
        >
          <a
            href="mailto:hoangtanphat97.xm@gmail.com"
            className="inline-block border-t pt-5"
          >
            hoangtanphat97.xm@gmail.com
          </a>
        </p>
      </div>
    </section>
  );
}

export default Contact;
